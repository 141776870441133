import React, { useEffect, useState } from "react";
import SideList from "../../../UI-component/SideList";
import ErrorAlert from "../../../UI-component/ErrorAlert";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import PopupTextField from "../../../UI-component/ui-el/PopupTextField";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../../../context/StepsContext";
import { onDataChange2, onDirectDataChange } from "../../../util/Dynamics";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import SelectionFormBox from "../../../UI-component/SelectionFormBox";
import CheckedFormBox from "../../../UI-component/CheckedFormBox";
import Loader from "../../../UI-component/Loader";

function Specific(props) {
    const dispatcher = useDispatch();

    const ctxSpecific = useSelector(state => state.stepSlice.data.preferenze.accessi);

    const ctxApplicant = useSelector(state => state.stepSlice.data.richiedente);
    const ctxPrefs = useSelector(state => state.stepSlice.data.preferenze);

    const [loading, setLoading] = useState(false);
    const [specific, setSpecific] = useState(ctxSpecific);
    const [error, setError] = useState({
        type: false,
        mod: false,
        motive: false,
        stating: false,
        quest: false
    });

    const onNext = () => {
        dispatcher(stepSlice.actions.dynamicSave({ id: "Preferenze", internalId: "accessi", data: specific }));

        props.next();
    }

    const errorChecker = () => {
        let err = true;

        setError(prev => {
            const type = !specific.tipologia ? true : false;
            const motive = !specific.motivo ? true : false;
            const stating = specific.dichiaro.length <= 0 ? true : false;
            const mod = !specific.modalita ? true : false;


            for (let i of Object.keys(specific.chiede)) {
                if (specific.chiede[i] !== "") {
                    err = false;
                    break;
                }
            }

            return { ...prev, type: type, mod: mod, motive: motive, stating: stating, quest: err };
        });
    }

    const update = (data) => {
        switch (data.id) {
            case "tipologia":
                setSpecific(prev => {
                    return { ...prev, tipologia: data.data }
                });
            break;
            case "dichiaro":
                setSpecific(prev => {
                    return { ...prev, dichiaro: data.data }
                });
                break;
            case "modalita":
                setSpecific(prev => {
                    return { ...prev, modalita: data.data }
                });
                break;
            default:
                break;
        }
    }

    const save = async () => {
        const newPref = { ...ctxPrefs };

        newPref.accessi = specific;

        const data = {
            richiedente: ctxApplicant?.length > 0 ? [{ ...ctxApplicant[0], selected: true }] : [],
            preferenze: newPref
        }

        setLoading(true);

        await props.save(data);

        dispatcher(stepSlice.actions.dynamicSave({ id: "Preferenze", internalId: "accessi", data: specific }));

        setLoading(false);
    }

    useEffect(() => {
        errorChecker();
    }, [specific]);

    const shouldShowAddressField = specific.modalita === "Invio tramite raccomandata";

    useEffect(() => {
        if (!shouldShowAddressField) {
          setSpecific(prev => ({ ...prev, indirizzo_spedizione: "" }));
        }
      }, [specific.modalita]);

    return (
        <div className="row">
            <SideList
                infoList={[
                    { element: "Tipologia", id: "#report-author" },
                    {element: "Modalità di ritiro", id: "#report-author"},
                    shouldShowAddressField 
                    ? { element: "Indirizzo di spedizione", id: "#report-author" }
                    : {},
                    { element: "Chiede", id: "#report-author" },
                    { element: "Motivo", id: "#report-author" },
                    { element: "Dichiaro", id: "#report-author" },

                ]} />

            <div className="col-12 col-lg-8 offset-lg-1">
                <div className="steppers-content" aria-live="polite">
                    <div className="it-page-sections-container">
                        {
                            <ErrorAlert errors={[{ name: "Tipologia", id: "#isee-info" }]} err={error.type} />
                        }
                        {
                            <ErrorAlert errors={[{ name: "Modalità di ritiro", id: "#isee-info" }]} err={error.mod} />
                        }
                        {
                            <ErrorAlert errors={[{ name: "Chiede", id: "#isee-info" }]} err={error.quest} />
                        }
                        {
                            <ErrorAlert errors={[{ name: "Motivo", id: "#isee-info" }]} err={error.motive} />
                        }
                        {
                            <ErrorAlert errors={[{ name: "Dichiaro", id: "#isee-info" }]} err={error.stating} />
                        }


                        <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-55%,-50%)", zIndex: "10000" }}>
                            <Loader loading={loading} />
                        </div>

                        <SelectionFormBox
                            showTitle={"Tipologia*"}
                            title="Tipologia"
                            description="Scegli la tipologia"
                            selectTitle="Seleziona una tipologia"
                            choices={[
                                { id: 1, value: "Richiesta di accesso civico generalizzato" },
                                { id: 2, value: "Richiesta di accesso agli atti amministrativi" }
                            ]}
                            defaultValue={specific?.tipologia}
                            oKey={"Ufficio"}
                            objectify={true}
                            update={update}
                        />

                        <SelectionFormBox
                            showTitle={"Modalità di ritiro*"}
                            title="Modalita"
                            description="Indica come preferisci ritirare la copia degli atti richiesti"
                            selectTitle="Seleziona una modalità"
                            choices={[
                                { id: 1, value: "Ritiro presso l'ufficio del Comune" },
                                { id: 2, value: "Invio tramite email" },
                                { id: 3, value: "Invio tramite raccomandata" }
                            ]}
                            defaultValue={specific?.modalita}
                            oKey={"Ufficio"}
                            objectify={true}
                            update={update}
                        />
                        {shouldShowAddressField && (
                        <SummaryCard type="Indirizzo di spedizione">
                            <PopupTextField
                                defaultValue={specific.indirizzo_spedizione}
                                fieldType="text"
                                id="indirizzo_spedizione"
                                fieldTitle="Indirizzo"
                                description="Indica l'indirizzo a cui verrà spedita copia degli atti richiesti"
                                onChange={(e) => { onDirectDataChange(e, setSpecific) }}
                            />
                        </SummaryCard>
                        )}
                        <SummaryCard
                            type="Chiede*"
                            description="(ai sensi e per gli effetti dell’art. 5, c.1, D.Lgs. n. 33/2013, delle relative disposizioni di attuazione dell’Amministrazione, disciplinanti il diritto di accesso ai dati e documenti detenuti dall’Amministrazione)">
                            <PopupTextField
                                defaultValue={specific.chiede?.documento}
                                fieldType="text"
                                id="documento"
                                fieldTitle="Documento"
                                description="Inserisci il documento"
                                onChange={(e) => { onDataChange2(e, setSpecific) }} />
                            <PopupTextField
                                defaultValue={specific.chiede?.informazione}
                                fieldType="text"
                                id="informazione"
                                fieldTitle="Informazione"
                                description="Inserisci l'informazione"
                                onChange={(e) => { onDataChange2(e, setSpecific) }} />
                            <PopupTextField
                                defaultValue={specific.chiede?.dato}
                                fieldType="text"
                                id="dato"
                                fieldTitle="Dato"
                                description="Inserisci il dato"
                                onChange={(e) => { onDataChange2(e, setSpecific) }}
                            />
                        </SummaryCard>

                        <SummaryCard type="Motivo*">
                            <PopupTextField
                                defaultValue={specific?.motivo}
                                fieldType="text"
                                id="motivo"
                                fieldTitle="Motivo"
                                description="Aggiungi un motivo alla tua richiesta"
                                onChange={(e) => { onDirectDataChange(e, setSpecific) }}
                            />
                        </SummaryCard>

                        <CheckedFormBox
                            type="checkbox"
                            title={["Accesso", "Dichiaro"]}
                            description=""
                            choices={[
                                { value: "1-di conoscere le sanzioni amministrative e penali previste dagli artt. 75 e 76 del D.P.R. 445/2000, “Testo unico delle disposizioni legislative e regolamentari in materia di documentazione amministrativa" },
                                { value: "2-di voler ricevere quanto richiesto personalmente presso l’ufficio competente del Comune, oppure al proprio indirizzo di posta elettronica, oppure che gli atti siano inviati all’indirizzo di spedizione indicato mediante raccomandata con avviso di ricevimento con spesa a proprio carico. Il rilascio di dati o documenti in formato elettronico o cartaceo è gratuito, salvo il rimborso del costo effettivamente sostenuto e documentato dall’amministrazione per la riproduzione su supporti materiali" }
                            ]}
                            defaultValue={specific.dichiaro}
                            update={update}
                        />

                    </div>
                </div>
                <NextBtnForm
                    next={onNext}
                    back={props.back}
                    saveRequest={save}
                    disabled={error.type || error.motive || error.stating || error.quest}
                />
                {
                    props.saved &&
                    <SuccessfullPopup />
                }

                {
                    props.fail &&
                    <SuccessfullPopup
                        isError={true}
                    />
                }
            </div>
        </div>
    )
}

export default Specific;