import React, { useEffect, useState } from "react";
import PopupBox from "../PopupBox";
import PopupTextField from "../ui-el/PopupTextField";

import stepSlice from "../../context/StepsContext";
import { useDispatch } from "react-redux";
import { checkValidity, createDocs, onDataChange2 } from "../../util/Dynamics";
import TransportUiForm from "../UIform/TransportUiForm";
import CafeteriaUiForm from "../UIform/CafeteriaUiForm";
import ImuUiForm from "../UIform/ImuUiForm";
import DisserviceUiForm from "../UIform/DisserviceUiForm";
import SoilUiForm from "../UIform/SoilUiForm,";
import VotiveUiForm from "../UIform/VotieUiForm";
import DeceasedUiForm from "../UIform/DeceasedUiForm";
import AccessUiForm from "./AccessUiForm";
import ParkingUIForm from "../UIform/ParkingUIForm";
import BonusForm from "../UIform/BonusForm";
import TowUiForm from "../UIform/TowUiForm";

function RegistryEditPopup(props) {
    const dispatcher = useDispatch();
    const [error, setError] = useState(false);

    const anagraficData = [props.registryData];

    let user = localStorage.getItem("user") ? JSON.parse(JSON.parse(localStorage.getItem("user"))?.data) : {};

    const documents = createDocs();

    // console.log("RegistryEditPopup", props.registryData)
    //Dynamically filterts the creation of
    //an object based on the given params
    const dynamicStepCreation = (docKey, registry = false, addresses = false, contacts = false, type) => {
        const defaultObj = {
            registry: {
                "nome": user.name,
                "cognome": user.familyName,
                "codice_fiscale": user.fiscalNumber,
                "data_di_nascita": user.dateOfBirth,
                "luogo_di_nascita": user.placeOfBirth,
                //"provincia_di_nascita": user.countyOfBirth,

            },
            addresses: {
                "residenza": "",
                "domicilio": "",
            },
            contacts: {
                "telefono": "",
                "email": "",
            }
        }

        const createdObj = {}

        if (registry)
            createdObj.anagrafica = { ...defaultObj.registry };
        if (addresses)
            createdObj.indirizzi = { ...defaultObj.addresses };
        if (contacts)
            createdObj.contatti = { ...defaultObj.contacts };
        if (type)
            createdObj.type = { type };
        if (docKey)
            createdObj.documenti = { ...documents.get(docKey) };


        // console.log("createdObj", createdObj);
        return { ...(props.registryData || {}), ...createdObj };
    }

    const dynamicBaseObjectCalculation = () => {
        let baseObj = {};
        let tmp = {};

        switch (props.service) {
            case 'RTS':
                if (props?.id === "Beneficiario") {

                    const type = props?.typeData ? props.typeData : "";

                    baseObj = dynamicStepCreation("RTSbeneficiary", false, false, false, type);

                    break;
                }

                baseObj = dynamicStepCreation("baseDoc", true, true, true, null);
                break;
            case 'RMS':
                if (props?.id === "Beneficiario") {
                    const type = props?.typeData ? props.typeData : "";
                    baseObj = dynamicStepCreation("RMSbeneficiary", false, false, false, type);

                    break;
                }

                baseObj = dynamicStepCreation("baseDoc", true, true, true, null);

                break;
            case 'PTI':
                if (props.registryData?.type?.type === "Persona giuridica") {
                    baseObj.anagrafica = {
                        "ragione_sociale": "",
                        "codice_fiscale": ""
                    }

                    baseObj.type = { type: props.registryData.type.type };

                    tmp = {
                        ...baseObj,
                        ...dynamicStepCreation("", false, false, true, props.typeData)
                    }

                    baseObj = tmp;
                }
                else {
                    baseObj = dynamicStepCreation("", true, false, true, props.typeData);
                    // baseObj.anagrafica.provincia_di_nascita = user?.countyOfBirth;
                }
                break;
            case 'SSD':
                tmp = {};

                baseObj.anagrafica = {
                    "nome": user.name || "",
                    "cognome": user.familyName || "",
                    "codice_fiscale": user.fiscalNumber || "",
                }

                tmp = {
                    ...baseObj,
                    ...dynamicStepCreation("", false, false, true, null)
                }


                baseObj = tmp;

                baseObj.selected = true;

                break;
            case 'SPA':
                baseObj = {
                    anagrafica: {
                        "nome": user?.name || anagraficData[0].nome,
                        "cognome": user?.familyName || anagraficData[0].cognome,
                        "email": anagraficData[0].email,
                        "telefono": anagraficData[0].telefono,
                        "codice_fiscale": anagraficData[0].codice_fiscale
                    }
                }

                break;
            case 'PSP':
                if (props.typeData !== "Persona giuridica") {
                    baseObj = dynamicStepCreation("PSPbaseDoc", true, true, true, null);

                    baseObj.selected = true;
                } else {
                    baseObj.anagrafica = {
                        "type": props.typeData,
                        "demoninazione": " ",
                        "iva": "",
                        "nome_legale": "",
                        "cognome_legale": "",
                        "CodiceFiscale": "",
                        "data_nascita_legale": "",
                        "luogo_nascita_legale": "",
                    };
                    baseObj.indirizzi = {
                        "addr_sede_legale": "",
                        "mun_sede_legale": "",
                        "addr_res_legale": "",
                        "mun_res_legale": ""
                    };
                    const tmp = {
                        ...baseObj,
                        ...dynamicStepCreation("PSPbaseDoc", false, false, true, null)
                    }

                    baseObj = tmp;

                    baseObj.selected = true;
                }
                break;
            case 'PCL':
                baseObj = dynamicStepCreation("", false, false, true, null);

                break;
            case 'PCO':
                baseObj = dynamicStepCreation("", false, false, true, null);
                break;
            case "RSD":
                if (props?.id === "Defunto") {
                    baseObj = dynamicStepCreation("RSDdoc", false, false, false, null);
                    const nonStandard = {
                        data_di_morte: "",
                        luogo_di_morte: "",
                        paternita: ""
                    };

                    baseObj.anagrafica = { ...baseObj.anagrafica, ...nonStandard }
                    break;
                }

                baseObj = dynamicStepCreation("baseDoc", true, true, true, null);

                break;
            case "RAAT":
                baseObj = dynamicStepCreation("baseDoc", true, true, true, null);
                break;
            case 'PPI':
                if (props?.id === "Disabile") {

                    const type = props?.typeData ? props.typeData : "";

                    let tmp = {};

                    baseObj.documenti = {
                        "carta_di_identita": "",
                        "base64_carta_di_identita": "",
                        "fototessera": "",
                        "base64_fototessera": "",
                        "certificato_ufficio": "",
                        "base64_certificato_ufficio": "",
                        "certificato_medico": "",
                        "base64_certificato_medico": "",
                        "denuncia": "",
                        "base64_denuncia": "",
                    }

                    tmp = {
                        ...dynamicStepCreation("", false, false, false, type, false),
                        ...baseObj
                    }

                    baseObj = tmp;
                    baseObj.selected = true;

                    break;
                }

                let temp = {};

                baseObj.documenti = {
                    "carta_di_identita": "",
                    "base64_carta_di_identita": "",
                    "attestazione_esercente": "",
                    "base64_attestazione_esercente": "",
                }

                temp = {
                    ...dynamicStepCreation("", true, true, true, null),
                    ...baseObj,
                }

                baseObj = temp;
                baseObj.anagrafica.tipo_richiedente = props.registryData.anagraficData?.tipo_richiedente;
                baseObj.selected = true;

                break;
            case "DBE":
                baseObj = dynamicStepCreation("baseDoc", true, true, true, null, true);

                break;
            case "PPC":
                baseObj = dynamicStepCreation("baseDoc", true, true, true, null, true);

                break;
            default:
                break;
        }

        return baseObj;
    }

    const [data, setData] = useState(dynamicBaseObjectCalculation());

    useEffect(() => {
        let obj = {}

        if (props.service === "SPA") {
            obj = structuredClone(anagraficData);
            return;
        }

        for (let i of anagraficData) {
            if (i.anagrafica.codice_fiscale === props.taxID) {
                obj = structuredClone(i);

                if (i.anagrafica?.data_di_nascita === "")
                    obj.anagrafica.data_di_nascita = user.dateOfBirth;

                if (i.anagrafica?.luogo_di_nascita === "")
                    obj.anagrafica.luogo_di_nascita = user.placeOfBirth;

                if (i.anagrafica?.nome === "")
                    obj.anagrafica.nome = user.name;

                if (i.anagrafica?.cognome === "")
                    obj.anagrafica.cognome = user.familyName;

                if (i.anagrafica?.codice_fiscale === "")
                    obj.anagrafica.codice_fiscale = user.fiscalNumber;

                break;
            }
        }


        setData(obj);
    }, [])

    const edit = () => {
        if (props.service === "SPA") {
            const newData = {
                Nome: data.anagrafica.nome,
                Cognome: data.anagrafica.cognome,
                Email: data.contatti.email
            }
            dispatcher(stepSlice.actions.dynamicEdit({ id: "Ufficio", data: newData }));
            props.close();
            return;
        }
        else if (props.service === "PSP") {
            if (checkValidity(data)) {
                dispatcher(stepSlice.actions.dynamicEdit({ id: "type_richiedente", data: data }));
                props.close();
            } else
                setError(true);

            return;
        }

        if (checkValidity(data)) {
            dispatcher(stepSlice.actions.dynamicEdit({ id: props.id, type: "anagrafica", data: data }));
            props.close();
        } else
            setError(true);
    }

    const edit2 = () => {
        console.log(data);

        if (checkValidity(data)) {
            props.update({ id: props.id, isEdit: true, data: [{ ...data }] });
            dispatcher(stepSlice.actions.alive());
            props.close();
        } else
            setError(true);

        return;
    }

    const dynamicObjectCalculation = () => {
        switch (props.service) {
            case 'RTS':
                return (
                    <>
                        <TransportUiForm
                            close={props.close}
                            save={edit2}
                            title={props?.id}
                            id={props.id}
                            error={error}
                            setCustomerData={setData}
                            user={user}
                            defaultValue={data}
                            isEdit={true}
                        />
                    </>
                )
            case 'RMS':
                return (
                    <>
                        <CafeteriaUiForm
                            close={props.close}
                            save={edit2}
                            title={props?.id}
                            id={props.id}
                            error={error}
                            setCustomerData={setData}
                            user={user}
                            defaultValue={data}
                            isEdit={true}
                        />
                    </>
                )
            case 'PTI':
                return (
                    <>
                        <ImuUiForm
                            close={props.close}
                            save={edit2}
                            title={props?.id}
                            id={props.id}
                            error={error}
                            setCustomerData={setData}
                            user={user}
                            defaultValue={data}
                            edit={true}
                            typeData={props?.typeData}
                        />
                    </>
                )
            case 'SSD':
                return (
                    <>
                        <DisserviceUiForm
                            close={props.close}
                            save={edit2}
                            title={props?.id}
                            id={props.id}
                            error={error}
                            setCustomerData={setData}
                            user={user}
                            defaultValue={data}
                            edit={true}
                        />
                    </>
                )
            case 'SPA':
                const isLogged = localStorage.getItem("access_token") !== undefined && localStorage.getItem("access_token") !== "" && localStorage.getItem("access_token") !== null
                // console.log("//////////////", localStorage.getItem("access_token"), isLogged)
                return (
                    <PopupBox close={props.close} save={edit2} title={props?.id}>
                        <PopupTextField
                            onChange={(e) => { onDataChange2(e, setData) }}
                            fieldType="text"
                            id="nome"
                            fieldTitle="Nome*"
                            defaultValue={data.anagrafica.nome}
                            disabled={isLogged}
                            description={" "}
                            error={props.error}
                        />
                        <PopupTextField
                            onChange={(e) => { onDataChange2(e, setData) }}
                            fieldType="text"
                            id="cognome"
                            fieldTitle="Cognome*"
                            defaultValue={data.anagrafica.cognome}
                            disabled={isLogged}
                            description={" "}
                        />
                        <>
                            <PopupTextField
                                onChange={(e) => { onDataChange2(e, setData) }}
                                fieldType="text"
                                id="codice_fiscale"
                                fieldTitle="Codice Fiscale*"
                                defaultValue={data.anagrafica.codice_fiscale}
                                regex="^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]{1}$"
                                disabled={isLogged}
                                error={props.error}
                            />

                            <PopupTextField
                                onChange={(e) => { onDataChange2(e, setData) }}
                                fieldType="email"
                                id="email"
                                fieldTitle="Email*"
                                defaultValue={data.anagrafica.email}
                                regex={`[\\w\\D]+.[\\w\\D]+@[\\w\\D]{3,20}[.][a-zA-Z\\D]{2,10}`}
                                error={props.error}
                            />

                            <PopupTextField
                                onChange={(e) => { onDataChange2(e, setData) }}
                                fieldType="text"
                                id="telefono"
                                fieldTitle="Telefono*"
                                defaultValue={data.anagrafica.telefono}
                                regex="[0-9]{8}"
                                error={props.error}

                            />
                        </>
                    </PopupBox>
                )
            case 'PSP':
                return (
                    <>
                        <SoilUiForm
                            close={props.close}
                            save={edit2}
                            title={props?.id}
                            id={props.id}
                            error={error}
                            setCustomerData={setData}
                            user={user}
                            defaultValue={data}
                            edit={true}
                            typeData={anagraficData[0].anagrafica.type}
                        />
                    </>
                )
            case 'PCL':
                return (
                    <>
                        <VotiveUiForm
                            close={props.close}
                            save={edit2}
                            title={props?.id}
                            id={props.id}
                            error={error}
                            setCustomerData={setData}
                            user={user}
                            defaultValue={data}
                            edit={true}
                            typeData={anagraficData[0].anagrafica.type}
                        />
                    </>
                )
            case 'PCO':
                return (
                    <>
                        <VotiveUiForm
                            close={props.close}
                            save={edit2}
                            title={props?.id}
                            id={props.id}
                            error={error}
                            setCustomerData={setData}
                            user={user}
                            defaultValue={data}
                            edit={true}
                            typeData={anagraficData[0].anagrafica.type}
                        />
                    </>
                )
            case "RSD":
                return <DeceasedUiForm
                    close={props.close}
                    save={edit2}
                    title={props?.id}
                    id={props.id}
                    error={error}
                    setCustomerData={setData}
                    user={user}
                    defaultValue={data}
                    edit={true}
                />
            case "RAAT":
                return (
                    <>
                        <AccessUiForm
                            close={props.close}
                            save={edit2}
                            title={props?.id}
                            id={props.id}
                            error={error}
                            setCustomerData={setData}
                            user={user}
                            defaultValue={data}
                        />
                    </>
                )
            case "PPI":
                // console.log(data);
                return (
                    <>
                        <ParkingUIForm
                            close={props.close}
                            save={edit2}
                            title={props?.id}
                            id={props.id}
                            error={error}
                            defaultValue={data}
                            setCustomerData={setData}
                            user={user}
                            isEdit={true}
                        />
                    </>
                )
            case "DBE":
                return (
                    <>
                        <BonusForm
                            close={props.close}
                            save={edit2}
                            title={props?.id}
                            id={props.id}
                            error={error}
                            setCustomerData={setData}
                            user={user}
                            defaultValue={data}
                        />
                    </>
                )

            case "PPC":
                return (
                    <>
                        <TowUiForm
                            close={props.close}
                            save={edit2}
                            title={props?.id}
                            id={props.id}
                            error={error}
                            setCustomerData={setData}
                            user={user}
                            defaultValue={data}
                        />
                    </>
                )
            default:
                break;
        }
    }

    return (
        <>
            {
                dynamicObjectCalculation()
            }
        </>
    )
}

export default RegistryEditPopup;